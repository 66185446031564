import React from 'react';
import './PartnerShip.css'; // Import your CSS file for styling
import eec from '../eec_logo.png';
const PartnerShip = () => {
    return (
        <div className="PartnerShip" id="PartnerShip">

           <h1>Strategic partner</h1>
           <div className='Image'>
            <img src={eec}/>
            </div>
            
        </div>
    );
};

export default PartnerShip;
