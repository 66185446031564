import React from 'react';
import Examples from './Examples';
import Ratal from '../Ratal.png'
import lessonPlan from '../lessonPlan.png'
import chatBot from '../chatBot.png'
import audit from '../audit.png'
const ExampleGenerator = () => {
    return (
        <div id='examples'>
                        <h1>Our work</h1>

          
<Examples id="example-1" image={audit} title={`Audit Automate`} content={` The Future of Compliance and Efficiency

In the world of audits and compliance, staying ahead of the curve is paramount. That's why we're excited to introduce our AI-driven audit solution, set to transform the way your company conducts audits.

Efficiency Redefined, Compliance Ensured

Our cutting-edge platform utilizes artificial intelligence to automate the audit process, comparing your documents against audit references such as ISO standards. This not only saves you time but also ensures that your audits are thorough and compliant, minimizing the risk of errors and non-compliance.

Empowering Your Audit Team, Elevating Your Standards

Gone are the days of manual audits and cumbersome paperwork. Our AI audit solution empowers your audit team to focus on high-value tasks, while the system handles the heavy lifting. With features like customizable audit templates and real-time analytics, our platform elevates your audit standards to new heights.

Scalable Solutions for Every Industry

Whether you're a small business or a multinational corporation, our platform offers scalable solutions tailored to your needs. From streamlining internal audits to ensuring compliance with industry regulations, our platform is designed to grow with your business, providing the flexibility you need in today's dynamic business environment.

Leading the Way in Audit Innovation

Join us on our mission to revolutionize the audit industry. With our AI audit solution, you can redefine the way audits are conducted, setting new standards for efficiency, accuracy, and compliance. Experience the future of audits today and stay ahead of the competition.

Empower Your Audit Process Today

Experience the power of AI-driven audits with our innovative platform. Say goodbye to manual audits and hello to a new era of audit efficiency and compliance. Join us and lead the way in audit innovation.` } alt='Audit automate'  />
   
<Examples id="example-0" image={chatBot} title={`ChatBot Generator`} content={`Introducing Our Website Chatbot Generator: Revolutionizing Customer Interactions

Say goodbye to lengthy customer service queues and hello to instant, personalized support with our innovative website chatbot generator. Our platform allows you to create a customized chatbot that can answer customer inquiries, provide support, and guide users through your website seamlessly.

Tailored to Your Needs

Our chatbot generator is designed to be flexible and adaptable to your business requirements. You can easily input your own questions and answers, customize the chatbot's responses to match your brand voice, and integrate it into your existing systems with ease.

Effortless Customer Engagement

With our chatbot, you can engage with customers 24/7, providing instant responses to common queries and issues. This not only improves customer satisfaction but also frees up your team to focus on more complex tasks.

Seamless Integration

Our chatbot generator seamlessly integrates with your website, CRM system, and other platforms, ensuring a smooth and efficient customer interaction process. You can also monitor and analyze chatbot interactions to gain valuable insights into customer behavior and preferences.

Empower Your Customer Service

Empower your customer service team with our chatbot generator. By automating repetitive tasks and providing instant responses, your team can focus on delivering exceptional service and building lasting relationships with your customers.

Join the Chatbot Revolution

Join the chatbot revolution and transform the way you engage with customers. Our chatbot generator is the perfect solution for businesses looking to enhance their customer service capabilities and improve customer satisfaction.

Get Started Today

Get started with our chatbot generator today and experience the future of customer interactions. Customize your chatbot, integrate it into your systems, and watch as it transforms the way you engage with customers online.` } alt='ChatBot Generator'  />
 

<Examples id="example-2" image={lessonPlan} title={`Lesson Plan Generator`} content={`Empowering Educators, Inspiring Learners: The Future of Lesson Planning

In the fast-paced world of education, teachers are faced with the challenge of creating engaging and effective lesson plans that meet the diverse needs of their students. Our revolutionary lesson generation platform is here to change that.

Unleashing Creativity, Uniting Classrooms

Our platform breaks down the barriers of traditional lesson planning, allowing teachers to unleash their creativity and create dynamic, interactive lessons that captivate and inspire students. With features such as customizable templates, multimedia integration, and real-time collaboration tools, our platform is redefining the way lessons are taught and learned.

Scalable Solutions for Every Institution

Whether you're a single teacher looking to enhance your classroom experience or a school district seeking to revolutionize curriculum development, our platform offers scalable solutions to meet your needs. Our platform is designed to grow with you, providing the flexibility and adaptability required in today's ever-changing educational landscape.

Data-Driven Insights, Student-Centric Learning

We believe that every student deserves a personalized learning experience. That's why our platform leverages data-driven insights to create lessons that are tailored to the individual needs and learning styles of each student. By putting students at the center of the learning process, we're empowering educators to create meaningful and impactful learning experiences.

Building the Future of Education, Together

Join us on our mission to build a brighter future for education. Whether you're a teacher looking to inspire your students, an institution seeking to transform your curriculum, or an edtech enthusiast eager to shape the future of learning, our platform has the tools and resources you need to succeed.

Experience the Future Today

Experience the future of lesson planning with our innovative platform. Say goodbye to outdated teaching methods and hello to a new era of education. Join us and be a part of the revolution in education.

` } alt='Lesson Plan Generator'  />
    
         </div>
    );
};

export default ExampleGenerator;



