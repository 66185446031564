import React from 'react';
import './Services.css';
import Typed from 'react-typed'; // Import the Typed component
import Counter from './Counter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain, faMobileAlt, faLaptopCode, faMagnifyingGlassChart } from '@fortawesome/free-solid-svg-icons';

const Services = () => {

   
    return (
        <div id='services'>
            <div className='achievements'>
                <h1 className='typing-animation'>
                    <Typed strings={['Our Services']} typeSpeed={70} showCursor={true} loop={true} backDelay={2000}/>
                </h1>          
                <div className='services-card'>
    <div className='counting-number'>
    <div className='opacity'>
    <FontAwesomeIcon className='icon' icon={faBrain} size="5x" />

        <p className='font-multiColor'>AI Tools</p>
        </div>
        <p className='description'>Our AI tools utilize advanced artificial intelligence to automate and optimize your business processes, delivering intelligent insights and efficiency.</p>
    </div>
</div>
<div className='services-card'>
    <div className='counting-number'>
    <div className='opacity'>
    <FontAwesomeIcon className='icon' icon={faMobileAlt} size="5x"  />

        <p className='font-multiColor'>Mobile Applications</p>
        </div>
        <p className='description'>We develop cutting-edge mobile applications that deliver seamless user experiences across all devices, tailored to meet your specific business needs.</p>
    </div>
</div>
<div className='services-card'>
    <div className='counting-number'>
    <div className='opacity'>
    <FontAwesomeIcon className='icon'icon={faLaptopCode} size="5x"/>
        
        <p className='font-multiColor'>Web Applications</p>
        </div>
        <p className='description'>Our web applications are designed to provide robust, scalable, and secure solutions that enhance your online presence and streamline your operations.</p>
    </div>
</div>
<div className='services-card'>
    <div className='counting-number'>
        <div className='opacity'>
    <FontAwesomeIcon className='icon' icon={faMagnifyingGlassChart} size="5x" />

        <p className='font-multiColor'>Data Analysis</p>
        </div>
        <p className='description'>Our data analysis services transform your data into actionable insights, helping you make informed decisions and drive strategic growth.</p>
    </div>
</div>

            </div>
          
        </div>
    );
};

export default Services;
