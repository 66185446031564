import './App.css';
import MainPage from './mainPage/MainPage';
import NavigationBar from './navigationBar/NavigationBar';
import AboutUs from './aboutUs/AboutUs';
import Services from './services/Services';
import ExampleGenerator from './examples/exampleGenerator';
import ContactUs from './contactUs/contactUs';
import PartnerShip from './StrategicPartnership/PartnerShip';
function App() {

  return (
    <div className="App">
      <header >
        <NavigationBar />
      </header>
      <MainPage id="home"/>
      <Services id="services"/>
      <AboutUs id="about"/>
      <ExampleGenerator id="examples"/>
      <PartnerShip id="PartnerShip"/>
      <ContactUs/>
    </div>
  );
}

export default App;
