import { v4 as uuidv4 } from "uuid";
import Card from "./Card";
import Carousel from "./Carousel";

import pic1 from '../chatBot.png';
import pic2 from '../audit.png'
import pic3 from '../lessonPlan.png';
const MainPage = () => {
    const cards = [
        {
            key: uuidv4(),
            content: (
              <Card imagen={pic1} />
            )
          },
          {
            key: uuidv4(),
            content: (
              <Card imagen={pic2} />
            )
          },
          {
            key: uuidv4(),
            content: (
              <Card imagen={pic3} />
            )
          }
       
      ];
  return (
    <div id="home" style={{
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <Carousel
        cards={cards}
        height="50vh"
        width="50%"
        margin="0 auto"
        offset={1}
        showArrows={false}
      />
      
    </div>
    );
};

export default MainPage;
