import React from 'react';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import './AboutUs.css';

const Section = ({ title, content }) => {
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold:0.5, // Adjust the threshold as needed
    });

    const fadeInProps = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(30px)',
        transition: 'opacity 0.5s, transform 0.5s',
    });
    
    
    
    return (
        <div className="section" ref={ref}>
            <animated.h2 className="section-heading" style={fadeInProps}>
                {title}
            </animated.h2>
            <animated.p className="paragraph" style={fadeInProps}>
                {content}
            </animated.p>
        </div>
    );
};

const AboutUs = () => {
    return (
        <div id="about" className="page-container">
            <Section
                title="About Us"
                content="Welcome to Auto Data IQ, the cutting-edge programming company that is shaping the future of technology in 2023 and beyond. Founded by a team of visionary experts, our company is driven by innovation, creativity, and a passion for pushing the boundaries of what's possible in the world of programming.

        At Auto Data IQ, we are proud to be home to some of the most brilliant and versatile programmers in the industry. Our team consists of a diverse group of individuals who possess a unique blend of technical prowess and creative ingenuity. Led by our exceptional and genius programmers, we have the power to turn your wildest technological dreams into reality."
            />
            <Section
                title="Innovation at its Core"
                content="Innovation is more than just a buzzword for us – it's the foundation upon which Auto Data IQ is built. Our programmers thrive on challenges and are dedicated to finding creative solutions to even the most complex problems. Whether you need a custom software application, a groundbreaking mobile app, or a sophisticated web platform, we have the expertise to make it happen."
            />
            <Section
                title="Unleashing the Power of Machine Learning"
                content="We are at the forefront of the machine learning revolution, harnessing the potential of this technology to revolutionize industries and enhance user experiences. Our experts in machine learning are well-versed in developing intelligent algorithms that can analyze, learn, and adapt, allowing your systems to evolve in real-time and make data-driven decisions like never before."
            />
            <Section
                title="Elevating Security Standards"
                content="Security is paramount in today's digital landscape, and at Auto Data IQ, we take it incredibly seriously. Our dedicated security team works tirelessly to ensure that all our solutions are fortified against cyber threats, safeguarding your valuable data and digital assets. You can trust us to provide robust, reliable, and resilient systems that prioritize your security without compromise."
            />
            <Section
                title="Your Vision, Our Mission"
                content="We understand that every project is unique, and your vision is at the heart of everything we do. Our collaborative approach ensures that we work closely with you to understand your goals, needs, and aspirations. Together, we transform your ideas into tangible, functional realities that exceed your expectations."
            />
            <Section
                title="Join the Auto Data IQ Experience"
                content="Whether you're a forward-thinking entrepreneur, an established business looking to innovate, or an individual with a groundbreaking idea, Auto Data IQ welcomes you to join us on this exciting journey. Explore the limitless possibilities of technology with our ingenious programmers, delve into the world of machine learning innovation, and rest easy knowing that your projects are fortified by top-tier security measures.

        Thank you for choosing Auto Data IQ – where innovation knows no bounds, and your success is our ultimate achievement."
            />
        </div>
    );
};

export default AboutUs;
