import React from 'react';
import './contactUs.css'; // Import your CSS file for styling

const ContactUs = () => {
    return (
        <div className="contactUs" id="contactUs">

           <h1>Contact Us</h1>
           <p>We are here to help you. Feel free to reach out to us!</p>
           <div className="contactForm">
               <a href="tel:+971504963255" style={{ fontFamily: "'Times New Roman', Times, serif" }}>Call us at +971 50 496 3255</a>
           </div>
           <p style={{marginTop:'30px'}}>Or</p>
           <div className="contactForm">
           <a href="mailto:AI@autodataiq.com" style={{ fontFamily: "'Times New Roman', Times, serif" }}>Email us at AI@autodataiq.com</a>
           </div>

            
        </div>
    );
};

export default ContactUs;
